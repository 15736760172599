import useProfile from 'hooks/useProfile';
import useSubscriptions from 'hooks/useSubscriptions';
import React, { createContext, useState, useEffect } from 'react';
import { Features } from 'utils/constants';
import useUsage from 'hooks/useUsage';

export const FeaturesContext = createContext();

const FeaturesProvider = ({ children }) => {
  const { billing, profile, isEnterprise } = useProfile();

  const {
    subscription,
    fetchSubscription,
    fetchCharges,
    charges,
    fetchCustomerMetadata,
    customerMetadata
  } = useSubscriptions(billing);
  const { credits } = useUsage();
  const [features, setFeatures] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(undefined);

  // Fetch the charges for the customer
  // This is used to determine if the user has topped up their credits

  useEffect(() => {
    if (!billing.customer) return;
    fetchCharges(billing.customer);
  }, [billing]);

  // Fetch the subscription
  useEffect(() => {
    if (billing.subscription && !subscription) {
      fetchSubscription(billing.subscription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing.subscription]);

  // Fetch the customer metadata
  useEffect(() => {
    if (billing.customer) {
      fetchCustomerMetadata({ customerId: billing.customer });
    }
  }, [billing.customer]);

  // Initialize the context and set the features
  useEffect(() => {
    // If logged out
    if (profile.isEmpty && profile.isLoaded) {
      setIsInitialized(true);
      return;
    }

    // If logged in but the user has no subscription
    if (
      !hasSubscription &&
      profile.isLoaded &&
      !profile.isEmpty &&
      credits !== undefined
    ) {
      setIsInitialized(true);
      return;
    }

    // If logged in and user has a subscription
    if (
      subscription &&
      hasSubscription &&
      subscription?.id &&
      profile.isLoaded &&
      !profile.isEmpty
    ) {
      setFeatures(subscription.features);
      setIsInitialized(true);
      return;
    }
  }, [
    subscription,
    profile.isEmpty,
    profile.isLoaded,
    hasSubscription,
    credits,
    isEnterprise
  ]);

  // Set the subscription status
  useEffect(() => {
    if (billing) {
      setHasSubscription(Boolean(billing.plan));
    }
  }, [billing]);

  /* This is used to distinguish between pay-as-you-go users
  and new users who receive some credits for free */
  const hasPaidCharges = charges?.some(
    (charge) => charge.status === 'succeeded' && charge.paid === true
  );

  // * If the user is a trial user
  const isTrialUser = customerMetadata?.isTrialUser === 'true';

  // * If the user has credits and (paid charges or isTrialUser) they are a pay-as-you-go user and have access to all features
  const isPayAsYouGoUser = credits > 0 && (hasPaidCharges || isTrialUser);

  const isFreePlanUser =
    (!hasSubscription && !isEnterprise && !isTrialUser && credits === 0) || // User has no subscription, is not an enterprise user, has no credits and is not a trial user
    (!hasSubscription && !isEnterprise && !isTrialUser && !hasPaidCharges); // User has no subscription, is not an enterprise user, has no credits and has not paid any charges

  const checkFeatureAccess = (feature) => {
    return isEnterprise || isPayAsYouGoUser || features?.includes(feature);
  };

  let value = {
    isPrecisionEnabled: true,
    isKonchMateEnabled: true,
    isContentGenerationEnabled: true,
    isTranscriptTranslationEnabled: true,
    isTranscriptSummarizationEnabled: true,
    isTranscriptExportEnabled: true,
    isMagicDocEnabled: true,
    hasSubscription: false,
    subscription,
    isFreePlanUser: false,
    isPayAsYouGoUser: false,
    isInitialized
  };

  const showNewPricingPlans = localStorage.getItem('showNewPricingPlans');

  // NEW PRICING PLANS
  if (showNewPricingPlans) {
    value = {
      isPrecisionEnabled: checkFeatureAccess(Features.Precision),
      isKonchMateEnabled: checkFeatureAccess(Features.KonchMate),
      isContentGenerationEnabled: checkFeatureAccess(
        Features.ContentGeneration
      ),
      isTranscriptTranslationEnabled: checkFeatureAccess(
        Features.TranscriptTranslation
      ),
      isTranscriptSummarizationEnabled: checkFeatureAccess(
        Features.TranscriptSummarization
      ),
      isTranscriptExportEnabled: checkFeatureAccess(Features.TranscriptExport),
      isMagicDocEnabled: checkFeatureAccess(Features.MagicDoc),
      hasSubscription,
      subscription,
      isFreePlanUser,
      isPayAsYouGoUser
    };
  }

  // if (!isInitialized) {
  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         height: '100vh'
  //       }}
  //     >
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <FeaturesContext.Provider value={value}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesProvider;
