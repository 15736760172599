import { Features } from 'utils/constants';

const highlightsHeader = 'What you get';
const footerTitle = 'Explore plans';
const footerSubtitle = 'Get access to this feature and more';
const buttonTitle = 'Explore plans';

export const featureDetails = [
  {
    highlightsHeader,
    footerTitle,
    footerSubtitle,
    buttonTitle,
    key: Features.Precision,
    title: 'Unlock 99% Accuracy with Precision',
    description:
      'Upgrade AI transcript to 99% accuracy with human intervention',
    highlights: [
      'Human intervention for proofreading in multiple languages.',
      'Guaranteed accuracy with 99% precision.',
      'Priority support for faster assistance.'
    ]
  },
  {
    highlightsHeader,
    footerTitle,
    footerSubtitle,
    buttonTitle,
    key: Features.KonchMate,
    title: 'Unlock KonchMate',
    description: 'Automate meeting transcriptions with our AI assistant.',
    highlights: [
      'Simplify meeting transcriptions with automated AI assistance.',
      'Seamless integration with your calendar.',
      'Priority support for faster assistance.'
    ]
  },
  {
    highlightsHeader,
    footerTitle,
    footerSubtitle,
    buttonTitle,
    key: Features.ContentGeneration,
    title: 'Unlock Content Generation',
    description: 'Create content from your transcripts using GPT.',
    highlights: [
      'Generate rich content from transcripts using GPT.',
      'Easily create summaries, articles, and reports.',
      'Priority support for faster assistance.'
    ]
  },
  {
    highlightsHeader,
    footerTitle,
    footerSubtitle,
    buttonTitle,
    key: Features.TranscriptTranslation,
    title: 'Unlock Transcript Translation',
    description: 'Translate transcripts into multiple languages.',
    highlights: [
      'Translation feature for multiple languages.',
      'Reach a global audience with translated content.',
      'Priority support for faster assistance.'
    ]
  },
  {
    highlightsHeader,
    footerTitle,
    footerSubtitle,
    buttonTitle,
    key: Features.TranscriptSummarization,
    title: 'Unlock Transcript Summarization',
    description: 'Quickly extract key insights from transcripts.',
    highlights: [
      'Summarization feature for extracting key insights.',
      'Highlight important points efficiently.',
      'Priority support for faster assistance.'
    ]
  },
  {
    highlightsHeader,
    footerTitle,
    footerSubtitle,
    buttonTitle,
    key: Features.TranscriptExport,
    title: 'Unlock Transcript Export and Download Formats',
    description: 'Download transcripts in various file formats.',
    highlights: [
      'Download transcripts in multiple formats for compatibility.',
      'Choose from a range of file formats such as txt (by speakers), csv( by speakers), xls, srt, vtt and more.',
      'Priority support for faster assistance.'
    ]
  },
  {
    highlightsHeader,
    footerTitle,
    footerSubtitle,
    buttonTitle,
    key: Features.FileSizeLimit,
    title: 'Unlock Larger File Transcriptions',
    description:
      'One or more files exceed the 10-minute limit on the free plan.',
    highlights: [
      'No limit on the duration of the audio for transcription.',
      'Advanced features for enhanced transcription capabilities.',
      'Priority support for faster assistance.'
    ]
  },
  {
    highlightsHeader,
    footerTitle,
    footerSubtitle,
    buttonTitle,
    key: Features.MagicDoc,
    title: 'Unlock Magic Doc Creation',
    description:
      'Instantly transform transcripts into polished content using AI',
    highlights: [
      'Automated document creation from transcripts.',
      'AI based conversation on your transcript supported by GPT',
      'Priority support for faster assistance.'
    ]
  }
];
